import React from 'react';

function AboutPage() {
    return (
        <>
        <h2>About Page</h2>
        <p>HowellCo creates is in incubator for software applications and also does identity and security consulting work. </p>
        </>
    );
}

export default AboutPage;