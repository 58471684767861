import React, { useState } from 'react';
import '../css/SupportPage.css'

function SupportPage() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setResponseMessage('Sending...');

        const response = await fetch('/send-support-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, message }),
        });

        if (response.ok) {
            setResponseMessage('Email sent successfully.');
            setEmail('');
            setMessage('');
        } else {
            setResponseMessage('Failed to send email.');
        }
    };

    return (
        <div className="support-container">
            <h2>Support</h2>
            <form onSubmit={handleSubmit} className="support-form">
                <div className="form-group">
                    <label>Your email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Message:</label>
                    <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                        className="form-control"
                    />
                </div>
                <button type="submit" className="btn-send">Send</button>
            </form>
            {responseMessage && <p className="response-message">{responseMessage}</p>}
        </div>
    );
}

export default SupportPage;
