import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { getData } from "../api/DataApi";

function DataPage() {
    const [data, setData] = useState([]);

    // useEffect accepts a function that it will call
    // _data is the data we got back from the API call.
    // empty array tells useEffect it should run only one time.
    useEffect(() => {
        getData().then(_data => setData(_data))
    },[]);

    return (
    <>
        <h2>Data</h2>
        <Table>
            <thead>
                <tr>
                    <th>Column 1</th>
                    <th>Column 2</th>
                    <th>Column 3</th>
                </tr>
            </thead>
            <tbody>
                {data.map(data => {
                    return (
                        <tr key={data.id}>
                            <td>{data.c1}</td>
                            <td>{data.c2}</td>
                            <td>{data.c3}</td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    </>
    );
}

export default DataPage;