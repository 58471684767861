import React from 'react';
import '../css/HomePage.css'

function HomePage() {
    return (
        <div className="center">
            <h1 className="title">HowellCo</h1>
            <img src="/HowellCo_icon.png" alt="HowellCo Logo" className="logo"/>
            <p className="description">HowellCo Software Entrepreneurship</p>
            <p className="description">Creating identity software for businesses.</p>
            <br />
            <br />
            <p className="description">
            Contact: <a href="mailto:dave@howellco.io">dave@howellco.io</a>
            </p>
        </div>
    );
}


export default HomePage;