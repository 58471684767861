import './App.css';
import HomePage from "./components/HomePage";
import DataPage from "./components/DataPage";
import AboutPage from "./components/AboutPage";
import SupportPage from "./components/SupportPage";
import Header from "./components/Header";
import Container from 'react-bootstrap/Container';
import { Route, Routes } from "react-router-dom";


function App() {
  return (
    <Container fluid>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/about/*" element={<AboutPage/>} />
        <Route path="/data/*" element={<DataPage/>} />
        <Route path="/support/*" element={<SupportPage/>} />
      </Routes>
    </Container>
  );
}

export default App;

