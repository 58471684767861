import { handleResponse, handleError } from "./apiUtils";

const baseUrl = process.env.REACT_APP_API_URL + "/data/";


export function getData() {
  return fetch(baseUrl)
    .then(handleResponse)
    .catch(handleError);
}
/*
export function getData() {
  return axios.get(baseUrl)
    .then(handleResponse)
    .catch(handleError);
}
*/
export function saveData(data) {
  return fetch(baseUrl + (data.id || ""), {
    method: data.id ? "PUT" : "POST", // POST for create, PUT to update when id already exists.
    headers: { "content-type": "application/json" },
    body: JSON.stringify(data)
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteData(dataId) {
  return fetch(baseUrl + dataId, { method: "DELETE" })
    .then(handleResponse)
    .catch(handleError);
}
