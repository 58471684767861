import React from "react";

function Header () {
    return (
        <nav>
            <a href="/">Home</a> | <a href="/about">About</a>  | <a href="/support">Support</a>  
        </nav>
    );
}

export default Header;
